// 以下に app/frontend/js 配下のスクリプトをimportする
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import '@fortawesome/fontawesome-free/css/all.css';

//scss import
import '@/stylesheets/customer.scss'

// js import
